import React from "react";
import { Link } from "gatsby";
import { autoColor } from "../../helpers/autocolor.helper";

interface NavigationItemProps {
    title: string;
    pathname: string;
    mobile: boolean;
}

const NavigationItem: React.FC<NavigationItemProps> = (props) => {
    const { title, pathname, mobile } = props;

    const activeDesktop =
        "first:ml-0 ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-primary-500 text-sm font-medium leading-5 text-gray-900 focus:outline-none focus:border-primary-700 transition duration-150 ease-in-out";
    const nonActiveDesktop =
        "first:ml-0 ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out";

    const activeMobile =
        `block pl-3 pr-4 py-2 border-l-4 border-primary-500 text-base font-medium text-${autoColor("100")} bg-primary-100 focus:outline-none focus:bg-primary-100 focus:border-primary-700 transition duration-150 ease-in-out`;
    const nonActiveMobile =
        "mt-1 block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out";

    const active = mobile ? activeMobile : activeDesktop;
    const nonActive = mobile ? nonActiveMobile : nonActiveDesktop;

    const isPartiallyActive = (values: any) => {
        const { location } = values;
        if (location.pathname.includes(pathname)) {
            if (pathname === "/" && location.pathname !== "/") {
                return {};
            }
            return { className: active };
        }
        return {};
    };

    return (
        <Link
            to={pathname}
            className={nonActive}
            activeClassName={active}
            getProps={isPartiallyActive}
        >
            {title}
        </Link>
    );
};

export default NavigationItem;
