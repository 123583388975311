import wsConfig from "../../webservice-config.json";

/*
Palauttaa jommankumman kahdesta väristä sen mukaan, onko syötteenä saatu väri tumma vai vaalea.
Ottaa syötteenä numeron 100-900, jota vastaava väri haetaan webservice-configista.
Pääasiallinen tarkoitus valita väri sellaisella tekstille, jonka taustaväri vaihtelee kaupan mukaan.
*/
export const autoColor = (colorCode: string) => {
  const primaryColors =  wsConfig.webservice.theme.colors.primary;
  const backgroundColor = +("0x" + primaryColors[colorCode as keyof typeof primaryColors].slice(1));

  const colorForLightBackground = "gray-800";
  const colorForDarkBackground = "white";

  // Määritä, onko väri tumma vai vaalea.
  const r = backgroundColor >> 16;
  const g = backgroundColor >> 8 & 255;
  const b = backgroundColor & 255;
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)); 
  return hsp > 127.5 ? colorForLightBackground : colorForDarkBackground;
}